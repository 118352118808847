<template>
	<w-layout align-center row wrap>
		<w-flex :class="{'mt-3 mb-2': isMobile}" xs12 md4 v-text="$t(value.title)" />
		<w-flex align-center d-flex grow row>
			<w-layout align-end row wrap>
				<w-flex xs12 md4 px-1>
					<w-select
						:items="getFrequencies()"
						item-value="value"
						:label="$t('notifications.profile.frequencies')"
						:value="value.frequency"
						:readonly="isNotDefaultFrequency()"
						:disabled="isNotDefaultFrequency()"
						@input="onFrequencyInput($event)"
					></w-select>
				</w-flex>
				<w-flex v-if="!isEverial" xs12 md4 px-1>
					<w-select
						:items="sources"
						item-value="id"
						item-text="text"
						return-object
						:label="$t('notifications.profile.author')"
						:value="value.sources"
						multiple
						@input="onSourceInput($event)"
					></w-select>
				</w-flex>
				<w-flex v-if="!isEverial" xs12 md4 px-1>
					<w-select
						:items="detailedChoices"
						item-value="value"
						:label="$t('notifications.profile.detailed')"
						:value="value.detailed"
						@input="onDetailedInput($event)"
					></w-select>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import NotificationsModuleGuard from '@/mixins/ModulesGuards/Notifications/NotificationsModuleGuard'

export default {
	name: 'NotificationTypeItem',
	mixins: [NotificationsModuleGuard],
	props: {
		value: {
			required: false,
			type: Object,
			default: new Object()
		},
		isEverial: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			detailedChoices: [
				{
					text: this.$t('notifications.profile.yes'),
					value: 1
				},
				{
					text: this.$t('notifications.profile.no'),
					value: 0
				}
			]
		}
	},
	computed: {
		...mapState({
			frequencies: state => state.notifications.frequencies,
			sources: state => state.notifications.sources
		}),
		isMobile () {
			return this.$vuetify.breakpoint.xsOnly;
		},
	},
	methods: {
		isNotDefaultFrequency: function () {
			return this.isEverial && this.value.frequency == 'instantly'
		},
		getFrequencies: function () {
			const isEverial = this.isEverial
			return this.frequencies.filter(function (frequency) {
				return isEverial ? ['instantly', 'never'].includes(frequency.value) : true
			})
		},
		onFrequencyInput: function (newValue) {
			let copiedValue = { ...this.value }
			copiedValue.frequency = newValue
			this.$emit('input', copiedValue)
		},
		onDetailedInput: function (newValue) {
			let copiedValue = { ...this.value }
			copiedValue.detailed = newValue
			this.$emit('input', copiedValue)
		},
		onSourceInput: function (newValue) {
			let copiedValue = { ...this.value }
			copiedValue.sources = newValue
			this.$emit('input', copiedValue)
		}
	}
}
</script>
